import axios from "axios"
import { baseURL } from '@/config'
import router from '@/router'
axios.defaults.timeout = 60 * 1000;
//axios.defaults.timeout = 50;
axios.defaults.baseURL = baseURL
let noToken = []
//axios.defaults.headers.post["Content-Type"] ="application/x-www-form-urlencoded";
axios.interceptors.request.use(
    config => {
        //console.log("config: ", config)
        if (!noToken.includes(config.url)) {
            config.headers.Authorization = sessionStorage.getItem('Authorization')
        } else {
            delete config.headers.Authorization
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    res => {
        //console.log("axios response: ", res)
        // 下载录音单独处理
        if(res.config.url == "/v2/api/downProxy"){
            return res
        }
        let { code, StatusCode } = res.data
        if(code == 409 && res.data.msg == "后台错误，请联系管理员"){
            //res.data.msg = "账号被挤掉请重新登录！"
            if(sessionStorage.getItem('Authorization')){
                res.data.msg = "登录状态已过期，请重新登录！"
            }else{
                res.data.msg = "请您先登录！"
            }
        }
        if (code == 200) {
            return res.data
        } else if(StatusCode == 200) {
            res.data.data = res.data
            return res.data
        } else if(code == 409) {
            setTimeout(() => {
                router.push({
                    path: '/login'
                })
            }, 2000)
            return Promise.reject(res.data)
        } else {
            return Promise.reject(res.data)
        }
    },
    error => {
        //console.error("err: " + error, error.message);
        // 网络异常
        if(error.message == 'Network Error'){
            return Promise.reject({
                msg: '网路异常，获取数据失败！'
            })
        }else if(error.message?.includes('timeout of')){
            return Promise.reject({
                msg: '网络请求超时！'
            })
        }
        return Promise.reject(error);
    }
)
export default {
    get(url, params, options = {}) {
        return new Promise((resolve, reject) => {
            // 导出时数据量大，超时时间设为0
            if(params && params.pageSize >= 100){
                options.timeout = 0
            }
            axios
                .get(url, { params, ...options })
                .then(res => {
                    //console.log("request get 执行了")
                    //console.log(res)

                    resolve(res.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    post(url, params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            axios
                .post(url, params, options)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};